// put your branch name and desired value for that branch for an env variable
// usage: replace all process.env.VAR_NAME_HERE with getBranchAwareEnv('VAR_NAME_HERE')
const envBranchMap = {
  PRODUCT_API_URL: {
    example_branch: 'https://dev-product-api.finn.com',
    'feat/USPD-1425': 'https://dev-product-api.finn.com',
    'feat/UA-9999-test': 'https://staging-product-api.finn.com',
  },
  HANDOVER_API_URL: {
    example_branch: 'https://dev-api-car-handover.finn.auto',
    'feat/UA-9999-test': 'https://dev-api-car-handover.finn.auto',
  },
  B2B_PRODUCT_API_URL: {
    example_branch: 'https://dev-product-api.finn.com/b2b',
  },
  CHECKOUT_API_URL: {
    example_branch: 'https://preview-450-api-checkout.finn.auto',
    'feat/UA-9999-test': 'https://preview-450-api-checkout.finn.auto',
  },
  AUTH_API_URL: {
    example_branch: 'https://dev--auth-new.netlify.app',
    'feat/UA-9999-test': 'https://dev--auth-new.netlify.app',
  },
  LOYALTY_API_URL: {
    example_branch: 'https://dev-api-loyalty.finn.auto',
  },
  LOYALTY_API_KEY: {},
  CP_DEPLOY_PREVIEW_URL: {
    example_branch: 'https://portal.finn.auto',
  },
  VOUCHER_API_URL: {
    example_branch: 'https://dev-api-voucher.finn.auto',
    'feat/UA-9999-test': 'https://dev-api-voucher.finn.auto',
  },
  CUSTOMER_SELF_SERVICE_API_URL: {
    example_branch: 'https://stg-customer-self-service.finn.auto',
    'feat/UA-5779-insurance-upgrade-changes-on-mycars':
      'https://customer-self-service.finn.auto',
  },
  SUBSCRIPTIONS_BASE_URL: {
    example_branch: 'https://stg-api-ops-subscriptions.finn.auto',
    'feat/UA-5779-insurance-upgrade-changes-on-mycars':
      'https://api-ops-subscriptions.finn.auto',
  },
  CAR_SERVICE_BASE_URL: {
    example_branch: 'https://stg-api-ops-cars.finn.auto',
    'feat/UA-5779-insurance-upgrade-changes-on-mycars':
      'https://api-ops-cars.finn.auto',
  },
  ADDONS_API_URL: {
    example_branch: 'https://addons-api.b2b-staging.finn.com',
    'feat/UA-5779-insurance-upgrade-changes-on-mycars':
      'https://addons-api.b2b-staging.finn.com',
  },

  // can't hardcode secret keys here for security reasons.
  // instead will use this switch to pick a different env var saved in parameter store
  // current these 2 variables are overridden: SUBSCRIPTION_SERVICE_KEY, CAR_SERVICE_KEY with SUBSCRIPTION_SERVICE_KEY_PROD_KEY, CAR_SERVICE_KEY_PROD_KEY
  USE_PROD_KEYS: {
    example_branch: 'yes',
    'feat/UA-5779-insurance-upgrade-changes-on-mycars': 'yes',
  },
};

const getAltVarName = (varName) => {
  switch (varName) {
    case 'CUSTOMER_PORTAL_URL':
      return 'CP_DEPLOY_PREVIEW_URL';
    default:
      return varName;
  }
};

const getBranchAwareEnv = (varName) => {
  // CI_COMMIT_REF_NAME is needed for netlify plugin `netlify-plugin-redirect-env-placeholders` as that happens before the build
  const branchName = process.env.CI_COMMIT_REF_NAME || process.env.BRANCH;
  const altVarName = getAltVarName(varName);
  const branchAwareVar =
    envBranchMap?.[altVarName]?.[branchName] ||
    envBranchMap?.[varName]?.[branchName];

  return branchAwareVar || process.env[altVarName] || process.env[varName];
};

module.exports = { getBranchAwareEnv };
